import React from "react";
import _ from "lodash";
import {parseResponse} from "../../../utils/api";
import {AGGREGATED_PORTFOLIO_ID} from "../../../utils/utils";
import {generateQueryParamsHash} from "../utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";

const SHARED_DATA_KEY = 'depots-financial-data';

export function useDepotFinancialData(
  customerId, portfolios, startDate, endDate, withHistorical, excludedDepotId, dataProvider, withOtherAssets) {

  const [data, setData] = React.useState({
    data: null,
    loading: true,
    error: null,
    updatedAt: +new Date(),
  });

  const paramsForHash = [startDate, endDate, withHistorical, withOtherAssets];
  const sharedData = useTabsSharedDataContext();

  const fetch = async () => {

    const _setState = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    };

    const hashKey = generateQueryParamsHash(customerId, portfolios, ...paramsForHash);

    _setState({
      data: null,
      loading: true,
      error: null,
      updatedAt: +new Date()
    });

    try {
      const portfoliosIds = _.isEmpty(portfolios)
        ? [AGGREGATED_PORTFOLIO_ID] // Use aggregated depot id in case customer does not have active depots
        : portfolios.map((portfolio) => portfolio.depotNumber);
      const response = await dataProvider.getDepotFinancialData(
        customerId, portfoliosIds, startDate, endDate, withHistorical, undefined, undefined, excludedDepotId, withOtherAssets);
      parseResponse(response, 'financial', (data) => {
        _setState({
          data: data,
          loading: false,
          error: null,
          updatedAt: +new Date()
        })
      }, (error) => {
        _setState({
          data: null,
          loading: false,
          error: error,
          updatedAt: +new Date()
        })
      })
    } catch (error) {
      _setState({
        data: null,
        loading: false,
        error: error,
        updatedAt: +new Date()
      })
    }
  };

  React.useEffect(() => {
    if (!!customerId && !_.isNil(portfolios)) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios, ...paramsForHash))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, JSON.stringify(portfolios), startDate, endDate, withHistorical, withOtherAssets]);

  return [data, fetch]

}